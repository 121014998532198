<template>
    <div class="image-header" :style="{
        backgroundImage: 'url(' + img + ')',
    }">
        <div @click="$router.back()">
            <div class="go-back">
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                    <path
                        d="M14.914 6.086C14.5389 5.71106 14.0303 5.50043 13.5 5.50043C12.9697 5.50043 12.4611 5.71106 12.086 6.086L5.67101 12.5L12.086 18.914C12.476 19.305 12.988 19.5 13.5 19.5C14.012 19.5 14.524 19.305 14.914 18.914C15.2889 18.5389 15.4996 18.0303 15.4996 17.5C15.4996 16.9697 15.2889 16.4611 14.914 16.086L11.329 12.5L14.914 8.914C15.2889 8.53894 15.4996 8.03033 15.4996 7.5C15.4996 6.96967 15.2889 6.46106 14.914 6.086Z"
                        fill="white" />
                </svg>
            </div>
        </div>
        <div class="flag">
            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
                <path
                    d="M1.8832 2.27813C1.80666 2.34448 1.74536 2.42657 1.7035 2.5188C1.66163 2.61103 1.64018 2.71122 1.64062 2.81251V12.6563C1.64062 12.8427 1.7147 13.0216 1.84656 13.1534C1.97843 13.2853 2.15727 13.3594 2.34375 13.3594C2.53023 13.3594 2.70907 13.2853 2.84093 13.1534C2.9728 13.0216 3.04687 12.8427 3.04687 12.6563V10.1789C4.38516 9.17579 5.51367 9.64454 7.18828 10.4719C8.13926 10.9406 9.22383 11.4797 10.3992 11.4797C11.2617 11.4797 12.1734 11.1902 13.1168 10.3734C13.1927 10.3076 13.2537 10.2263 13.2955 10.1349C13.3374 10.0435 13.3591 9.94425 13.3594 9.84376V2.81251C13.3594 2.67769 13.3207 2.54571 13.2478 2.43229C13.1749 2.31887 13.071 2.22879 12.9483 2.17278C12.8257 2.11678 12.6896 2.09721 12.5561 2.1164C12.4227 2.1356 12.2976 2.19276 12.1957 2.28106C10.7479 3.53555 9.58652 3.06094 7.81172 2.18262C6.16348 1.36348 4.11152 0.348054 1.8832 2.27813ZM11.9531 9.50743C10.6148 10.5111 9.48633 10.0412 7.81172 9.21446C6.45469 8.54063 4.82402 7.73438 3.04687 8.54708V3.14591C4.38516 2.14278 5.51367 2.61153 7.18828 3.43887C8.13926 3.90762 9.22383 4.44669 10.3992 4.44669C10.9358 4.44749 11.4661 4.33132 11.9531 4.10626V9.50743Z"
                    fill="white" />
            </svg>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        img: String
    }
}
</script>
<style scoped>
.image-header {
    background-color: rgba(0,0,0,0.3);
    background-blend-mode: darken;
    margin: 20px;
    width: calc(100% - 40px);
    height: 300px;
    border-radius: 15px;
    background-size: cover;
    background-position: center;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    position: relative;
    z-index: 0;
}


.flag {
    background: linear-gradient(180deg, #8E56F8 0%, #091D85 100%);
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 10px;
    top: 10px;
}
</style>