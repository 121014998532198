import { createApp } from "vue";
import App from "./App.vue";
import router from "./router/index";
import i18n from './plugins/i18n';

const app = createApp(App);
//Add router
app.use(router);
app.use(i18n);

app.mount("#app");
