<template>
    <div>
        <carousel :items-to-show="1.5">
                <slide v-for="(attraction, index) in attractions" :key="index">
                    <div class="slide">
                        <router-link :to="'/attrait/'+attraction.elementNumber">
                            <div class="img-attraction-slider" :style="{
                            backgroundImage: 'url(' + attraction.main_image.image_url + ')',
                        }"></div>
                        <div class="slide-text">
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"
                                    fill="none">
                                    <path
                                        d="M10 11.6667C11.8384 11.6667 13.3334 10.1717 13.3334 8.33333C13.3334 6.495 11.8384 5 10 5C8.16169 5 6.66669 6.495 6.66669 8.33333C6.66669 10.1717 8.16169 11.6667 10 11.6667ZM10 6.66667C10.9192 6.66667 11.6667 7.41417 11.6667 8.33333C11.6667 9.2525 10.9192 10 10 10C9.08085 10 8.33335 9.2525 8.33335 8.33333C8.33335 7.41417 9.08085 6.66667 10 6.66667Z"
                                        fill="black" />
                                    <path
                                        d="M9.51666 18.1783C9.6577 18.2791 9.82668 18.3332 9.99999 18.3332C10.1733 18.3332 10.3423 18.2791 10.4833 18.1783C10.7367 17.9992 16.6908 13.7 16.6667 8.33334C16.6667 4.65751 13.6758 1.66667 9.99999 1.66667C6.32416 1.66667 3.33333 4.6575 3.33333 8.32917C3.30916 13.7 9.26333 17.9992 9.51666 18.1783ZM9.99999 3.33334C12.7575 3.33334 15 5.57584 15 8.3375C15.0175 12.0358 11.3433 15.3567 9.99999 16.4458C8.65749 15.3558 4.98249 12.0342 4.99999 8.33334C4.99999 5.57584 7.24249 3.33334 9.99999 3.33334Z"
                                        fill="black" />
                                </svg>
                            </div>
                            <div> {{ attraction.name }}</div>
                        </div>
                        </router-link>
                    </div>
                </slide>
            </carousel>
    </div>
</template>
<script>
import { Carousel, Slide } from "vue3-carousel";
export default {
    components:{
        Carousel,
        Slide
    },
    props:{
        attractions:Array
    }
}
</script>
<style scoped>
.slide {
    height: 100%;
    width: 100%;
    margin-right: 10px;
    color: #000;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: baseline;
}
.slide>a{
    text-decoration: none;
    color: #000;
}
.img-attraction-slider {
    border-radius: 15px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    height: 275px;
    width: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    margin-bottom: 5px;
}

.slide-text {
    display: flex;
}


</style>