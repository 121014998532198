<template>
  <div>
    <div class="map-searcher">
      <div class="position-relative">
        <input type="text" placeholder="Chercher un endroit" @keyup="(e)=>searchPlaces(e)" v-model="search"/>
        <CustomIcon name="magnifier" class="search-icon"></CustomIcon>
        <CustomIcon name="itinerary" class="itinerary-icon"></CustomIcon>
      </div>
      <div class="list-of-places" v-if="listPlaces.length>0">
          <div v-for="(place, index) in listPlaces" :key="index" class="places-items" @click="selectFeature(place)">
            <img :src="place.properties.img" class="places-img"/>
            {{ place.properties.name }}
          </div>
      </div>
    </div>
    <div id="map"></div>
    <div class="bottom-right-widgets">
      <img :src="position" @click="myposition=!myposition;track()">
    </div>
  </div>
</template>
<script>
import maplibregl from "maplibre-gl";
import CustomIcon from "@/components/CustomIcon.vue";
import { openDB, getAllObjectsFromStore } from '@/indexedDB'
import eglise from '@/assets/images/eglise.png'
import edifice from '@/assets/images/edifice.png'
import fleuve from '@/assets/images/fleuve.png'
import monument from '@/assets/images/monument.png'
import musee from '@/assets/images/musee.png'
import parc from '@/assets/images/parc.png'
import rue from '@/assets/images/rue.png'
import position from '@/assets/images/position.png'
import itinerary from '@/assets/images/itinerary.png'
import dot from '@/assets/images/dot.png'
export default {
  data() {
    return {
      position,
      itinerary,
      dot,
      myposition:false,
      geojson:null,
      listPlaces:[],
      search:null
    };
  },
  components: {
    CustomIcon,
  },
  methods: {
    async getAllData() {
      try {
        await openDB()
        const attractions = await getAllObjectsFromStore()
        await this.convertToGeojson(attractions)
      } catch (error) {
        console.log(error)
      }
    },
    async convertToGeojson(attractions) {
      let geojson = {
        type: "FeatureCollection",
        features: []
      }
      attractions.forEach(element => {
        let attractionsData = JSON.parse(element.attraction)
        let coordinates = attractionsData.coordinate.split(',')
        let feature = {
          type: "Feature",
          properties: {
            elementNumber:attractionsData.elementNumber,
            name: attractionsData.name,
            img:attractionsData.main_image.image_url,
            type:attractionsData.type
          },
          geometry: {
            type: "Point",
            coordinates: [parseFloat(coordinates[1]), parseFloat(coordinates[0])]
          }
        }
        geojson.features.push(feature)
      })
      this.geojson = geojson
      this.addGeojson(this.geojson)
    },
    addGeojson(geojson) {
      this.fitBBox(geojson)
      this.geojson=geojson
      geojson.features.forEach((feature) => {
        if (!isNaN(feature.geometry.coordinates[0])) {
          var marker = document.createElement("div");
          marker.style.backgroundSize = "30px 30px";
          marker.style.backgroundRepeat = "no-repeat";
          marker.style.width = "30px";
          marker.style.borderRadius = "100%";
          marker.style.height = "30px";
          marker.style.cursor = "pointer";
          switch (feature.properties.type) {
            case 'Église':
              marker.style.backgroundImage = "url(" + eglise+ ")";
              marker.style.backgroundPosition = "center";
            break;
            case 'Monument':
              marker.style.backgroundImage = "url(" + monument+ ")";
              marker.style.backgroundPosition = "center";
            break;
            case 'Parc':
              marker.style.backgroundImage = "url(" + parc+ ")";
              marker.style.backgroundPosition = "center";
            break;
            case 'Musée':
              marker.style.backgroundImage = "url(" + musee+ ")";
              marker.style.backgroundPosition = "center";
            break;
            case 'Édifice':
              marker.style.backgroundImage = "url(" + edifice+ ")";
              marker.style.backgroundPosition = "center";
            break;
            case 'Rue':
              marker.style.backgroundImage = "url(" + rue+ ")";
              marker.style.backgroundPosition = "center";
            break;
            case 'Fleuve':
              marker.style.backgroundImage = "url(" + fleuve + ")";
              marker.style.backgroundPosition = "center";
            break;
          
            default:
              break;
          }


          var popup = new maplibregl.Popup({ offset: 25 }).setHTML(
            '<div><h2 class="pop-up-name">'+feature.properties.name+'</h2><div><img src="'+feature.properties.img+'" class="img-pop-up"/></div><a href="/attrait/'+feature.properties.elementNumber+'" class="pop-up-btn">Voir le profil</a></div>'
          );
          new maplibregl.Marker({
            className: "marker",
            element: marker,
          }).setLngLat(feature.geometry.coordinates).setPopup(popup).addTo(this.map);
        }
      })
    },
    fitBBox(data) {
      // Get the bounding box of the GeoJSON data
      var bounds = new maplibregl.LngLatBounds();
      if(data.features.length>1){
        data.features.forEach(function (feature) {
          if (!isNaN(feature.geometry.coordinates[0])) {
            bounds.extend(feature.geometry.coordinates);
          }
        });
        this.map.fitBounds(bounds, { padding: 20 });
      }
      else{
        this.map.flyTo({
        center: data.features[0].geometry.coordinates,
        zoom: 17,
        essential: true,
        });
      }

      
    },
    track: function() {
      let vm = this;
      if (this.myposition) {
        vm.getPosition()
        this.interval = setInterval(function() {
          vm.getPosition();
        }, 5000);
      } else {
        window.userPosition.remove();
        clearInterval(this.interval);
      }
    },
    getPosition: function() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(this.showPosition);
      }
      else{
        alert('No navigation')
      }
    },
    showPosition: function(position) {
      this.myPositionLat = position.coords.latitude;
      this.myPositionLon = position.coords.longitude;
      if (window.userPosition != undefined) {
        window.userPosition.remove();
      }
      var el = document.createElement("div");
      el.style.backgroundImage = "url(" + dot + ")";
      el.style.backgroundSize = "100px 100px";
      el.style.width = "100px";
      el.style.height = "100px";
      window.userPosition = new maplibregl.Marker({
            className: "marker",
            element: el,
          }).setLngLat([position.coords.longitude, position.coords.latitude]).addTo(this.map);
        this.map.flyTo({
        center: [this.myPositionLon, this.myPositionLat],
        essential: true // this animation is considered essential with respect to prefers-reduced-motion
      });
    },
    searchPlaces(e){
      let value = e.target.value
      this.listPlaces=this.geojson.features.filter(element=>element.properties.name.includes(value.toLowerCase()))

    },
    selectFeature(e){
      this.listPlaces=[]
      this.map.flyTo({
        center: e.geometry.coordinates,
        zoom: 20,
        essential: true,
        })
        this.search=e.properties.name
    }
  },
  mounted() {
    this.map = new maplibregl.Map({
      container: "map",
      style:
        "https://api.maptiler.com/maps/dac9ccfc-6132-4fc9-96a5-61099130860e/style.json?key=SouA4ezyTVcYOGXs9H7i", // stylesheet location
      center: [-72.538, 46.346], // starting position [lng, lat]
      zoom: 16, // starting zoom
      maxZoom: 17,
    })
    this.map.on('style.load', () => {
      this.getAllData()
    })
  },
};
</script>
<style scoped>
#map {
  border: 2px solid #091d85;
  margin: 10px;
  height: calc(100vh - 20px);
  width: calc(100vw - 20px);
  border-radius: 10px;
  box-sizing: border-box;
}

.map-searcher {
  position: absolute;
  z-index: 1000;
  top: 20px;
}

.map-searcher input {
  z-index: 1000;
  border-radius: 10px;
  border: 1px solid #bab7b7;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  height: 40px;
  margin-left: 20px;
  margin-right: 20px;
  width: calc(100vw - 40px);
  padding-left: 10px;
  box-sizing: border-box;
}

.map-searcher input::placeholder {
  font-family: "Montserrat", sans-serif;
}

.search-icon {
  position: absolute;
  z-index: 3000;
  top: 55%;
  transform: translatey(-50%);
  right: 70px;
}
.itinerary-icon {
  position: absolute;
  z-index: 3000;
  top: 55%;
  transform: translatey(-50%);
  right: 30px;
}
.markerPosition{
  background: red;
}
.bottom-right-widgets{
  position:absolute;
  right: 15px;
  bottom:70px;
  display: flex;
  flex-direction: column;
}
.list-of-places{
  padding: 20px;
  border: 1px solid #bab7b7;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  box-sizing:content-box;
  margin-left: 20px;
  margin-right: 20px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  max-height: 200px;
  overflow-y: auto;
  width: calc(100vw - 40px);
}
.places-items{
  padding:5px;
  display:flex;
  align-items: center;
}
.places-items:hover{
  border-radius: 3px;
  background-color: rgba(9, 29, 133,0.1);
  cursor: pointer;
}
.places-img{
  width:30px;
  height: 30px;
  border-radius: 3px;
  margin-right: 10px;
}
@media only screen and (min-width: 900px) {
    .map-searcher input{
        width:30vw;
    }
    .list-of-places{
      box-sizing: border-box;
      width:30vw;
    }
  }
</style>
