<template>
    <div class="container plr-20px">
        <TitleHeader :title="$t('about.title')"></TitleHeader>
        <div v-if="about" class="mb-70px" v-html="about['contenu-'+this.$i18n.locale]">

        </div>
    </div>
</template>
<script>
import TitleHeader from '../components/TitleHeader'
import axios from 'axios'
export default {
    data(){
        return {
            about:null
        }
    },
    components:{
        TitleHeader
    },
    methods:{
        getAbout(){
            axios.get('https://sandbox.cdgdeveloppeur.com/patrimoinetr/wp-json/wp/v2/pages/3312').then(results=>{
                if(this.$){
                    this.about=results.data.acf
                }

            })
        }
    },
    mounted(){
        this.getAbout()
    }
}
</script>
<style>
.container img{
    width:100%;
    border-radius: 15px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
</style>