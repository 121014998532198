<template>
    <div class="mb-70px container">
        <TitleHeader :title="$t('attractionsMultiple.title')" class="plr-20px"></TitleHeader>
        <p class="plr-20px">
            {{ $t("attractionsMultiple.description") }}
        </p>
        <div class="type-container plr-20px">
            <div v-for="(type, index) in types" :key="index" class="type-item" :class="{ active: activeType===type }" @click="activeType=type">
               <CustomIcon :name="type"></CustomIcon> {{ type }}
            </div>
        </div>
        <div class="p-20px">
            <div class="d-flex justify-center" v-if="loading">
                <LoaderSection></LoaderSection>
            </div>
            <CarouselAttractions :attractions="filterAttractions"></CarouselAttractions>
        </div>
    </div>
</template>
<script>
import TitleHeader from "@/components/TitleHeader";
import axios from "axios";
import "vue3-carousel/dist/carousel.css";
import CustomIcon from "@/components/CustomIcon"
import CarouselAttractions from "@/components/CarouselAttractions"
import {openDB, addObjectToStore, clearObjectStore} from "@/indexedDB"
import LoaderSection from "@/components/LoaderSection"
export default {
    data() {
        return {
            attractions: [],
            types:[],
            activeType:'',
            loading:true, 
        };
    },
    computed:{
        filterAttractions() {
            return this.attractions.filter(element=>element.type===this.activeType)
        },
    },
    components: {
        TitleHeader,
        CustomIcon,
        CarouselAttractions,
        LoaderSection
    },
    methods: {
        async getAttraction() {
            await axios
                .get(
                    "https://cms.patrimoine3r.quebec/index.php/wp-json/v1/endpoint"
                )
                .then((response) => {
                    this.attractions = response.data;
                    this.saveLocally(this.attractions)
                    this.getAllUniqueType(response.data)   
                    return 'done'         
                }).catch((error)=>{
                    return error  
                })
        },
        getAllUniqueType(attractions){
            let unique = []
            attractions.forEach(element=>{
                if(!unique.includes(element.type)){
                    unique.push(element.type)
                }
            })
            this.activeType=unique[0]
            this.types=unique
        },
        async saveLocally(attractions){
            try{
                await openDB()
                await clearObjectStore()
                attractions.forEach(async (element)=>{
                    let attraction = {
                        elementNumber:element.elementNumber,
                        attraction:JSON.stringify(element)
                    }
                    await addObjectToStore(attraction)
                })
            }catch(error){
                console.log(error)
            }
        }
    },
    mounted() {
        this.getAttraction().then(()=>{this.loading=false})
        
    },
};
</script>
<style scoped>
.type-container{
    display: flex;
    flex-wrap: wrap;
}
.type-item{
    color: #BAB7B7;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-right:5px;
    display: flex;
    align-items: center;
    padding:5px
}
.active{
    border-radius: 10px;
    background: linear-gradient(90deg, #091D85 0%, #8E56F8 151.93%);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
</style>
