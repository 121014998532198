<template>
    <div class="title-container">
        <div>
            <div class="type">
                <CustomIcon :name="type"></CustomIcon>{{type}}
            </div>
            <h1>{{ title }}</h1>
        </div>
        <div>
            <div class="heart" @click="addOrRemoveFromFavorite(elementNumber)" :class="{'active':favorites && favorites.includes(elementNumber)}">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path
                        d="M13.9062 2.1875C12.3367 2.1875 10.9438 2.80703 10 3.87188C9.05625 2.80703 7.66328 2.1875 6.09375 2.1875C4.72674 2.18915 3.41618 2.73293 2.44956 3.69956C1.48293 4.66618 0.939154 5.97674 0.9375 7.34375C0.9375 12.9953 9.20391 17.5109 9.55547 17.7008C9.69209 17.7744 9.84483 17.8129 10 17.8129C10.1552 17.8129 10.3079 17.7744 10.4445 17.7008C10.7961 17.5109 19.0625 12.9953 19.0625 7.34375C19.0608 5.97674 18.5171 4.66618 17.5504 3.69956C16.5838 2.73293 15.2733 2.18915 13.9062 2.1875ZM13.4773 13.3094C12.3893 14.2327 11.2261 15.0637 10 15.7938C8.77387 15.0637 7.61073 14.2327 6.52266 13.3094C4.82969 11.857 2.8125 9.64219 2.8125 7.34375C2.8125 6.47351 3.1582 5.63891 3.77356 5.02356C4.38891 4.4082 5.22351 4.0625 6.09375 4.0625C7.48438 4.0625 8.64844 4.79688 9.13203 5.97969C9.20242 6.15212 9.32257 6.29967 9.47715 6.40354C9.63174 6.50741 9.81376 6.56288 10 6.56288C10.1862 6.56288 10.3683 6.50741 10.5228 6.40354C10.6774 6.29967 10.7976 6.15212 10.868 5.97969C11.3516 4.79688 12.5156 4.0625 13.9062 4.0625C14.7765 4.0625 15.6111 4.4082 16.2264 5.02356C16.8418 5.63891 17.1875 6.47351 17.1875 7.34375C17.1875 9.64219 15.1703 11.857 13.4773 13.3094Z"
                        fill="white" />
                </svg>
            </div>
        </div>
    </div>
</template>
<script>
import CustomIcon from "../components/CustomIcon"
export default {
    data(){
        return {
            favorites:[]
        }
    },
    components: {
        CustomIcon
    },
    props: {
        title: String,
        type:String,
        elementNumber:String
    },
    methods:{
        addOrRemoveFromFavorite(elementNumber){
            let favorites=[]
            if(localStorage.getItem('favorites')){
                favorites=JSON.parse(localStorage.getItem('favorites'))
            }
            if(favorites.includes(elementNumber)){
                var index = favorites.indexOf(elementNumber);
                if (index !== -1) {
                    favorites.splice(index, 1);
                }
            }else{
                favorites.push(elementNumber)
            }
            this.favorites=favorites
            localStorage.setItem('favorites', JSON.stringify(favorites))
        }
    },
    mounted(){
        this.favorites=JSON.parse(localStorage.getItem('favorites'))
    }
}
</script>
<style>
.title-container {
    background-color: white;
    border-radius: 15px;
    padding: 10px 10px 5px 10px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    margin-left: 40px;
    margin-right: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;

}

.title-container>div>h1 {
    color: #000;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 0;
}

.heart {
    background: linear-gradient(180deg, #8E56F8 0%, #091D85 100%);
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.active{
    background:linear-gradient(180deg, #E67671 0%, #D43D94 100%);
}
.type {
    display: flex;
    color: #BAB7B7;
    align-items: center;
}
</style>