<template>
    <div class="mb-70px container">
        <HeaderBackground></HeaderBackground>
        <div class="img-container" v-if="attraction">
            <div @click="$router.back()">
            <div class="go-back">
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                    <path
                        d="M14.914 6.086C14.5389 5.71106 14.0303 5.50043 13.5 5.50043C12.9697 5.50043 12.4611 5.71106 12.086 6.086L5.67101 12.5L12.086 18.914C12.476 19.305 12.988 19.5 13.5 19.5C14.012 19.5 14.524 19.305 14.914 18.914C15.2889 18.5389 15.4996 18.0303 15.4996 17.5C15.4996 16.9697 15.2889 16.4611 14.914 16.086L11.329 12.5L14.914 8.914C15.2889 8.53894 15.4996 8.03033 15.4996 7.5C15.4996 6.96967 15.2889 6.46106 14.914 6.086Z"
                        fill="white" />
                </svg>
            </div>
        </div>
            <img :src="attraction.image[$route.params.index].image_url"/>
        </div>
        <div class="plr-20px" v-if="attraction">
           <h2> {{ attraction.image[$route.params.index].image_title }}</h2>
           <i>{{attraction.image[$route.params.index].image_credit}}</i>
           <div class="description">
            {{attraction.image[$route.params.index].image_description}}
           </div>
        </div>
    </div>
</template>
<script>
import HeaderBackground from '@/components/HeaderBackground'
import { openDB, GetAllObjectsFromStoreFilter } from '@/indexedDB'
export default{
    data(){
        return {
            attraction:null
        }
    },
    components:{
        HeaderBackground
    },
    methods:{
        async getData() {
            try {
                await openDB()
                const attraction = await GetAllObjectsFromStoreFilter('elementNumber', this.$route.params.id)
                this.attraction = JSON.parse(attraction[0].attraction)
            } catch (error) {
                console.log(error)
            }
        }
    },
    mounted(){
        this.getData()
    }
}
</script>
<style scoped>
.img-container{
    margin: 20px;
    width: calc(100% - 40px);
    position: relative;
}
.img-container>img{
    width:100%;
    border-radius: 15px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
.description{
    margin-top:15px;
}

</style>