<template>
    <div class="header-background">
        <svg xmlns="http://www.w3.org/2000/svg" width="auto" height="auto" viewBox="0 0 390 151" fill="none">
            <path d="M0 0H390V64.5435L0 151V0Z" fill="url(#paint0_linear_3_161)" />
            <defs>
                <linearGradient id="paint0_linear_3_161" x1="195" y1="0" x2="195" y2="151"
                    gradientUnits="userSpaceOnUse">
                    <stop stop-color="#091D85" />
                    <stop offset="1" stop-color="#8E56F8" />
                </linearGradient>
            </defs>
        </svg>
    </div>
</template>
<style>
.header-background{
    top:0;
    left:0;
    position:absolute;
    z-index:-2;
    width: 100vw;
}
@media only screen and (min-width: 900px) {
    .header-background>svg{
        display:none;
    }
  }
</style>