<template>
    <div class="container plr-20px">
        <TitleHeader :title="$t('favorites.title')"></TitleHeader>
        <CarouselAttractions :attractions="filterAttractions"></CarouselAttractions>
        <div v-if="filterAttractions.length===0" class="p-20px">
            <div class="descriptions-p">{{ $t('favorites.noData') }}</div>
            <div class="img-center"><img src="/images/nothing.png" /></div>
        </div>
    </div>
</template>
<script>
import TitleHeader from '../components/TitleHeader'
import CarouselAttractions from '../components/CarouselAttractions'
import { openDB, getAllObjectsFromStore } from '@/indexedDB'

export default {
    data() {
        return {
            attractions: [],
            filterAttractions:[],
        }
    },
    components: {
        TitleHeader,
        CarouselAttractions
    },
    methods: {
        async getAllData() {
            try {
                await openDB()
                const attractions = await getAllObjectsFromStore()
                await this.convertToFavorite(attractions)
            } catch (error) {
                console.log(error)
            }
        },
        async convertToFavorite(attractions){
            let favorites = JSON.parse(localStorage.getItem('favorites'))
            attractions.forEach(element=>{
                if(favorites.includes(element.elementNumber))
                this.filterAttractions.push(JSON.parse(element.attraction))
            })
        }
    },
    mounted() {
        this.getAllData()
    }
}
</script>
<style scoped>
.img-center{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top:40px;
}
.img-center>img{
    width:50%;
}
</style>