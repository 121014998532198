import { createI18n } from "vue-i18n";

const messages = {
  fr: {
    menu:{
      attraction:"Attractions",
      coupsCoeur:"Coups de coeur",
      circuits:"Circuits",
      cartes:"Cartes",
      about:"À propos"
    },
    attractionsMultiple: {
      title: "Explorez les attraits",
      description:
        "Voici la liste des 71 attrait d'Epérience P3R regroupés par catégorie. Touchez à un attrait pour en apprendre plus, voir des images d'archives et choisir vos Coups de Coeur",
    },
    attractionSingle:{
      button:'Voir sur la carte'
    },
    favorites:{
      title: "Mes Coups de Coeur",
      noData:"Vous n'avez actuellement pas de coup de coeur"
    },
    tours: {
      title: "Découvrez nos circuits",
      description:
        "Découvrez une partie de l'histoire de Trois-Rivières en parcourant son \"Circuit patrimonial\" complet ou seulement une de ses parties. En effet, vous avez l'opportunité de parcourir:",
      minutes:"minutes",
      lieux:"Lieux"
      },
    about: {
      title: "A propos d'Expérience P3R",
    },
  },
  en: {
    menu:{
      attraction:"Attractions"
    },
    attractionsMultiple: {
      title: "Explore attractions",
      description:
        "Here is the list of the 71 attractions of P3R an attraction to learn more, see archive images and choose your Favorites.",
    },
    attractionSingle:{
      button:'See on the map'
    },
    favorites:{
      title: "Favorites",
      noData:"You have no favorites now"
    },
    tours: {
      title: "Discover our tours",
      description:
        'Discover part of the history of Trois-Rivières by browsing its complte "Heritage Circuit" or only one of its parts. Indeed, you have the opportunity to browse: ',
      minutes:"minutes",
      lieux:"Attractions"
      },
    about: {
      title: "About P3R Experience",
    },
  },
};

const i18n = createI18n({
  legacy: false, // you must specify 'legacy: false' option
  locale: localStorage.getItem('lang') ? localStorage.getItem('lang') :'fr', // set locale
  fallbackLocale: "en", // set fallback locale
  messages, // set locale messages
});
export default i18n;
