<template>
    <div class="mb-70px container plr-20px">
        <TitleHeader v-if="tour" :title="tour.acf['nom-' + this.$i18n.locale]"></TitleHeader>
        <div >
            <div class="descriptions-p" v-if="tour">
                {{ tour.acf['description-' + this.$i18n.locale] }}
            </div>
            <div id="map">
            </div>
            <div v-for="(attraction, index) in attractions" :key="index">
                <router-link :to="'/attrait/' + attraction.elementNumber">
                    <div class="attraction-circuit-item">
                        <div class="attraction-circuit-item-img" :style="{
                            backgroundImage: 'url(' + attraction.main_image.image_url + ')'
                        }">
                        </div>
                        <div>
                            <div class="attraction-circuit-title">{{ attraction.name }}</div>
                            <div class="attraction-circuit-type">Rue</div>
                        </div>
                    </div>
                </router-link>
            </div>
        </div>
    </div>
</template>
<script>
import TitleHeader from '@/components/TitleHeader.vue'
import axios from 'axios'
import maplibregl from "maplibre-gl";
import { openDB, getAllObjectsFromStore } from '@/indexedDB'
export default {
    data() {
        return {
            tour: null,
            map: null,
            attractions: []
        }
    },
    components: {
        TitleHeader
    },
    methods: {
        getCircuit() {
            axios.get('https://sandbox.cdgdeveloppeur.com/patrimoinetr/index.php/wp-json/wp/v2/circuits/' + this.$route.params.id + '?acf_format=standard').then(result => {
                this.tour = result.data
                this.getAllData()
            }).catch()
        },
        async getAllData() {
            try {
                await openDB()
                const attractions = await getAllObjectsFromStore()
                attractions.forEach(element => {
                    this.tour.acf.attraits.forEach(element2 => {
                        if (JSON.parse(element.attraction).id === element2.ID)
                            this.attractions.push(JSON.parse(element.attraction))
                    })
                })
            } catch (error) {
                console.log(error)
            }
        },
    },
    mounted() {
        this.getCircuit()
        this.map = new maplibregl.Map({
            container: "map",
            style:
                "https://api.maptiler.com/maps/dac9ccfc-6132-4fc9-96a5-61099130860e/style.json?key=SouA4ezyTVcYOGXs9H7i", // stylesheet location
            center: [-72.538, 46.346], // starting position [lng, lat]
            zoom: 14, // starting zoom
            maxZoom: 17,
        })
    },
}
</script>
<style>
#map {
    margin-top: 20px;
    border: 2px solid #091d85;
    height: 300px;
    width: 100%;
    border-radius: 10px;
}

.attraction-circuit-item {
    padding: 10px;
    margin-top: 10px;
    border-radius: 15px;
    border: 1px solid #BAB7B7;
    background: #FFF;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    display: flex;
    align-items: center;
}

.attraction-circuit-title {
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    flex: 1 0;
}

.attraction-circuit-type {
    color: #BAB7B7;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.attraction-circuit-item-img {
    border-radius: 15px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    width: 77px;
    height: 72px;
    background-position: center;
    background-size: cover;
    margin-right: 20px;
    flex: 1 0 77px;
    max-width: 77px;
}
</style>