<template>
    <div class="mb-70px container">
        <TitleHeader :title="$t('tours.title')" class="plr-20px"></TitleHeader>
        <p class="plr-20px">
            {{ $t('tours.description') }}
        </p>
        <div v-for="(tour, index) in tours" :key="index" class="plr-20px">
            <RouterLink :to="'/circuit/'+tour.id">
                <div class="tour-item" :style="{
                    backgroundImage: 'url('+tour.acf.image+')',
                }">
                    <div class="tour-title">{{ tour.acf['nom-' + this.$i18n.locale] }}</div>
                    <div class="tour-comment">{{ tour.acf['commentaire-' + this.$i18n.locale] }}</div>
                    <div class="tour-stat">
                        <div class="d-flex vertical-center"><CustomIcon name="clock"></CustomIcon><div class="plr-5px">{{ tour.acf['duree'] }}  {{ $t("tours.minutes") }}</div></div><div class="d-flex vertical-center"><CustomIcon name="place"></CustomIcon><div class="plr-5px"> {{ tour.acf['attraits'].length }} {{ $t("tours.lieux") }}</div></div>
                    </div>
                </div>
            </RouterLink>
        </div>
    </div>
</template>
<script>
import TitleHeader from '../components/TitleHeader'
import axios from 'axios'
import CustomIcon from "../components/CustomIcon"
export default {
    data() {
        return {
            tours: []
        }
    },
    components: {
        TitleHeader,
        CustomIcon
    },
    methods: {
        getTours() {
            axios.get('https://sandbox.cdgdeveloppeur.com/patrimoinetr/wp-json/wp/v2/circuits?acf_format=standard').then(response => {
                this.tours = response.data.reverse()
                console.log(response)
            }).catch(error => {
                console.error(error)
            })
        }
    },
    mounted() {
        this.getTours()
    }
}
</script>
<style>
.tour-item {
    border-radius: 10px;
    background-color:rgba(0,0,0,0.2);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    height: 190px;
    width: 100%;
    background-position: center;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: end;
    padding:10px;
    box-sizing: border-box;
    background-blend-mode: darken;
    margin-top:20px;
}
.tour-title{
    color: #FFF;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.tour-comment{
    color: #FFF;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top:5px;
}
.tour-stat{
    color: #FFF;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    display:flex;
    margin-top:5px;
}
a{
    text-decoration: none;
}
</style>