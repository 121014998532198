<template>
  <div>
    <router-view></router-view>
    <BottomNavbar class="fixed-bottom"></BottomNavbar>
  </div>
</template>
<script>
import BottomNavbar from './components/BottomNavbar.vue';
export default {
  name: 'App',
  components: {
    BottomNavbar
  }
}
</script>
<style>
@import url("@/assets/css/style.css");

.fixed-bottom {
  position: fixed;
  bottom: 0;
  left: 0;
}
</style>
