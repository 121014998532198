<template>
    <div v-if="attraction" class="clear-menu container">
        <HeaderBackground></HeaderBackground>
        <ImageHeader :img="attraction.main_image.image_url"></ImageHeader>
        <TitleAttractionSingle class="minus-mt-40px" :title="attraction.name" :type="attraction.type" :elementNumber="attraction.elementNumber">
        </TitleAttractionSingle>
        <div class="plr-40px">
            <div v-html="attraction.description">

            </div>
        </div>
        <div class="mt-20px">
            <carousel :items-to-show="2.5">
                <slide v-for="(image, index) in attraction.image" :key="index">
                    <div class="slide">
                        <router-link :to="'/image/'+$route.params.id+'/'+index">
                        <div class="image" :style="{
                        backgroundImage: 'url(' + image.image_url + ')',
                    }">

                    </div>
                    </router-link>
                    </div>
                </slide>
            </carousel>
        </div>
        <div class="container">
            <router-link to="/carte" class="btn-primary ">{{$t('attractionSingle.button')}}</router-link>
        </div>
    </div>
</template>
<script>
import HeaderBackground from '@/components/HeaderBackground.vue';
import ImageHeader from '@/components/ImageHeader.vue';
import TitleAttractionSingle from '@/components/TitleAttractionSingle.vue';
import { Carousel, Slide } from "vue3-carousel";
import { openDB, GetAllObjectsFromStoreFilter } from '@/indexedDB'
export default {
    data() {
        return {
            attraction: null
        }
    },
    components: {
        ImageHeader,
        TitleAttractionSingle,
        HeaderBackground,
        Carousel,
        Slide
    },
    methods: {
        async getData() {
            try {
                await openDB()
                const attraction = await GetAllObjectsFromStoreFilter('elementNumber', this.$route.params.id)
                this.attraction = JSON.parse(attraction[0].attraction)
            } catch (error) {
                console.log(error)
            }
        }
    },
    mounted() {
        this.getData()
    }
}
</script>
<style>
.minus-mt-40px {
    position: relative;
    margin-top: -40px;
    z-index: 2000;
}

.image {
    border-radius: 15px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    height: 200px;
    width: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    margin-bottom: 5px;
    margin-left:10px;
}
.slide {
    height: 100%;
    width: 100%;
    margin-right: 10px;
}
</style>

