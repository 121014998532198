<template>
    <div class="menu-bottom">
        <router-link to="/">
            <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 35 35" fill="none">
                <path
                    d="M17.5 20.4167C20.7171 20.4167 23.3334 17.8004 23.3334 14.5833C23.3334 11.3663 20.7171 8.75 17.5 8.75C14.2829 8.75 11.6667 11.3663 11.6667 14.5833C11.6667 17.8004 14.2829 20.4167 17.5 20.4167ZM17.5 11.6667C19.1086 11.6667 20.4167 12.9748 20.4167 14.5833C20.4167 16.1919 19.1086 17.5 17.5 17.5C15.8915 17.5 14.5834 16.1919 14.5834 14.5833C14.5834 12.9748 15.8915 11.6667 17.5 11.6667Z"
                    fill="#BAB7B7" />
                <path
                    d="M16.6542 31.8121C16.901 31.9883 17.1967 32.0831 17.5 32.0831C17.8033 32.0831 18.099 31.9883 18.3458 31.8121C18.7892 31.4985 29.2089 23.975 29.1667 14.5833C29.1667 8.15061 23.9327 2.91666 17.5 2.91666C11.0673 2.91666 5.83332 8.15061 5.83332 14.576C5.79103 23.975 16.2108 31.4985 16.6542 31.8121ZM17.5 5.83332C22.3256 5.83332 26.25 9.7577 26.25 14.5906C26.2806 21.0627 19.8508 26.8742 17.5 28.7802C15.1506 26.8727 8.71936 21.0598 8.74999 14.5833C8.74999 9.7577 12.6744 5.83332 17.5 5.83332Z"
                    fill="#BAB7B7" />
            </svg>
        </router-link>
        <router-link to="/favoris">
            <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 35 35" fill="none">
                <path
                    d="M24.3359 3.82812C21.5893 3.82812 19.1516 4.9123 17.5 6.77578C15.8484 4.9123 13.4107 3.82812 10.6641 3.82812C8.27179 3.83102 5.97832 4.78263 4.28673 6.47423C2.59513 8.16582 1.64352 10.4593 1.64062 12.8516C1.64063 22.7418 16.1068 30.6441 16.7221 30.9764C16.9612 31.1051 17.2285 31.1725 17.5 31.1725C17.7715 31.1725 18.0388 31.1051 18.2779 30.9764C18.8932 30.6441 33.3594 22.7418 33.3594 12.8516C33.3565 10.4593 32.4049 8.16582 30.7133 6.47423C29.0217 4.78263 26.7282 3.83102 24.3359 3.82812ZM23.5854 23.2914C21.6812 24.9072 19.6457 26.3614 17.5 27.6391C15.3543 26.3614 13.3188 24.9072 11.4146 23.2914C8.45195 20.7498 4.92188 16.8738 4.92188 12.8516C4.92188 11.3286 5.52685 9.86809 6.60372 8.79122C7.68059 7.71435 9.14114 7.10938 10.6641 7.10938C13.0977 7.10938 15.1348 8.39453 15.9811 10.4645C16.1042 10.7662 16.3145 11.0244 16.585 11.2062C16.8555 11.388 17.1741 11.485 17.5 11.485C17.8259 11.485 18.1445 11.388 18.415 11.2062C18.6855 11.0244 18.8958 10.7662 19.0189 10.4645C19.8652 8.39453 21.9023 7.10938 24.3359 7.10938C25.8589 7.10938 27.3194 7.71435 28.3963 8.79122C29.4731 9.86809 30.0781 11.3286 30.0781 12.8516C30.0781 16.8738 26.548 20.7498 23.5854 23.2914Z"
                    fill="#BAB7B7" />
            </svg>
        </router-link>
        <router-link to="/circuits">
            <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 35 35" fill="none">
                <path
                    d="M13.125 17.5V17.5146M8.75 18.9583V18.9729M24.7917 21.875L18.9583 16.0417M18.9583 21.875L24.7917 16.0417M4.375 10.2083L13.125 5.83334L21.875 10.2083L30.625 5.83334V24.7917L21.875 29.1667L13.125 24.7917L4.375 29.1667V10.2083Z"
                    stroke="#BAB7B7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
        </router-link>
        <router-link to="/carte">
            <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 35 35" fill="none">
                <path
                    d="M32.1885 2.78635C32.1885 2.78075 32.1902 2.7755 32.1902 2.7699C32.1902 2.51639 32.0896 2.27325 31.9104 2.09389C31.7313 1.91453 31.4882 1.81363 31.2347 1.81335H13.8457V1.8172L2.81086 12.8524H2.80981V32.2301H2.81086C2.81124 32.4837 2.9122 32.7271 3.09161 32.9063C3.27101 33.0855 3.51419 33.1862 3.76776 33.1863H31.2354C31.4533 33.1859 31.6645 33.1111 31.834 32.9742C32.0035 32.8374 32.1212 32.6467 32.1675 32.4338H32.1885V32.2462C32.1885 32.241 32.1899 32.2361 32.1899 32.2308C32.1899 32.2256 32.1885 32.2207 32.1885 32.2154V2.78635ZM23.6737 5.2983H28.7035V13.9073L22.364 7.56735L23.6737 5.2983ZM13.8495 13.8086C14.1024 13.8076 14.3446 13.7066 14.5231 13.5275C14.7017 13.3484 14.802 13.1059 14.8022 12.8531V5.2983H21.658L12.1485 21.7693L6.29476 15.9156V13.8086H13.8495ZM6.29476 29.7017V18.3845L11.2448 23.3345L7.56911 29.7017H6.29476ZM9.58476 29.7017L21.4603 9.13255L28.7039 16.3762V29.7017H9.58476Z"
                    fill="#BAB7B7" />
                <path
                    d="M21.7105 15.9516C19.3848 15.9516 17.5014 17.837 17.5014 20.161C17.5014 21.0917 17.807 21.9492 18.3183 22.646L21.034 27.3497C21.0504 27.3826 21.0679 27.4148 21.0889 27.4445L21.0945 27.454L21.0959 27.4533C21.163 27.5467 21.2506 27.6235 21.3519 27.678C21.4532 27.7324 21.5656 27.763 21.6805 27.7675C21.7954 27.7719 21.9098 27.7501 22.0151 27.7037C22.1203 27.6572 22.2135 27.5874 22.2877 27.4995L22.2947 27.5033L22.3209 27.4582C22.3617 27.4047 22.3954 27.3463 22.4214 27.2842L25.0842 22.6719C25.6272 21.9474 25.9207 21.0662 25.9207 20.1607C25.9196 17.837 24.0363 15.9516 21.7105 15.9516ZM21.6689 22.295C21.3965 22.2951 21.1268 22.2415 20.8751 22.1373C20.6234 22.0332 20.3948 21.8804 20.2022 21.6878C20.0095 21.4953 19.8568 21.2666 19.7525 21.015C19.6483 20.7633 19.5947 20.4936 19.5948 20.2212C19.5947 19.811 19.7163 19.41 19.9441 19.0688C20.172 18.7277 20.4959 18.4618 20.8749 18.3048C21.2539 18.1477 21.6709 18.1066 22.0733 18.1866C22.4756 18.2666 22.8452 18.4641 23.1353 18.7542C23.4254 19.0442 23.623 19.4138 23.7031 19.8161C23.7831 20.2185 23.7421 20.6355 23.5851 21.0145C23.4281 21.3936 23.1623 21.7175 22.8212 21.9454C22.4801 22.1733 22.0791 22.295 21.6689 22.295Z"
                    fill="#BAB7B7" />
            </svg>
        </router-link>
        <router-link to="/a-propos">
            <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 35 35" fill="none">
                <path
                    d="M17.5 32.0833C9.44561 32.0833 2.91666 25.5544 2.91666 17.5C2.91666 9.44561 9.44561 2.91666 17.5 2.91666C25.5544 2.91666 32.0833 9.44561 32.0833 17.5C32.0833 25.5544 25.5544 32.0833 17.5 32.0833ZM17.5 29.1667C20.5942 29.1667 23.5616 27.9375 25.7496 25.7496C27.9375 23.5616 29.1667 20.5942 29.1667 17.5C29.1667 14.4058 27.9375 11.4383 25.7496 9.25041C23.5616 7.06249 20.5942 5.83332 17.5 5.83332C14.4058 5.83332 11.4383 7.06249 9.25041 9.25041C7.06249 11.4383 5.83332 14.4058 5.83332 17.5C5.83332 20.5942 7.06249 23.5616 9.25041 25.7496C11.4383 27.9375 14.4058 29.1667 17.5 29.1667ZM17.5 14.5833C17.8868 14.5833 18.2577 14.737 18.5312 15.0105C18.8047 15.2839 18.9583 15.6549 18.9583 16.0417V23.3333C18.9583 23.7201 18.8047 24.091 18.5312 24.3645C18.2577 24.638 17.8868 24.7917 17.5 24.7917C17.1132 24.7917 16.7423 24.638 16.4688 24.3645C16.1953 24.091 16.0417 23.7201 16.0417 23.3333V16.0417C16.0417 15.6549 16.1953 15.2839 16.4688 15.0105C16.7423 14.737 17.1132 14.5833 17.5 14.5833ZM17.5 13.125C17.1132 13.125 16.7423 12.9713 16.4688 12.6979C16.1953 12.4244 16.0417 12.0534 16.0417 11.6667C16.0417 11.2799 16.1953 10.9089 16.4688 10.6355C16.7423 10.362 17.1132 10.2083 17.5 10.2083C17.8868 10.2083 18.2577 10.362 18.5312 10.6355C18.8047 10.9089 18.9583 11.2799 18.9583 11.6667C18.9583 12.0534 18.8047 12.4244 18.5312 12.6979C18.2577 12.9713 17.8868 13.125 17.5 13.125Z"
                    fill="#BAB7B7" />
            </svg>
        </router-link>
    </div>
</template>
<style>
.menu-bottom {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    background: rgba(255, 255, 255, 0.80);
    box-shadow: 0px -2px 4px 0px rgba(0, 0, 0, 0.25);
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-around;
    z-index:10000;
}
@media only screen and (min-width: 900px) {
    .menu-bottom {
        display: none;
    }
  }
</style>