import { createWebHistory, createRouter } from "vue-router";

import AttractionsMultiple from "@/views/AttractionsMultiple.vue";
import AttractionsSingle from "@/views/AttractionsSingle.vue";
import FavoritesPage from "@/views/FavoritesPage.vue";
import ToursMultiple from "@/views/ToursMultiple.vue";
import ToursSingle from "@/views/ToursSingle.vue";
import MapPage from "@/views/MapPage.vue";
import AboutP3r from "@/views/AboutP3r.vue";
import ImageSingle from "@/views/ImageSingle.vue";

const routes = [
  {
    path: "/",
    name: "AttractionsMultiple",
    component: AttractionsMultiple,
  },
  {
    path: "/attrait/:id",
    name: "AttractionsSingle",
    component: AttractionsSingle,
  },
  {
    path: "/favoris",
    name: "FavoritesPage",
    component: FavoritesPage,
  },
  {
    path: "/circuits",
    name: "ToursMultiple",
    component: ToursMultiple,
  },
  {
    path: "/circuit/:id",
    name: "ToursSingle",
    component: ToursSingle,
  },
  {
    path: "/image/:id/:index",
    name: "ImageSingle",
    component: ImageSingle,
  },
  {
    path: "/carte",
    name: "MapPage",
    component: MapPage,
  },
  {
    path: "/a-propos",
    name: "AboutP3r",
    component: AboutP3r,
  },
];
const router = createRouter({
  history: createWebHistory('/'), // Set the base path
  routes,
});
export default router;
