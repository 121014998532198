const DB_NAME = "partimoinetr";
const DB_VERSION = 1;
const OBJECT_STORE_NAME = "attractions";

let db;

const openDB = () => {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open(DB_NAME, DB_VERSION);

    request.onerror = (event) => {
      reject(`Error opening database: ${event.target.error}`);
    };

    request.onsuccess = (event) => {
      db = event.target.result;
      resolve(db);
    };

    request.onupgradeneeded = (event) => {
      db = event.target.result;
      if (!db.objectStoreNames.contains(OBJECT_STORE_NAME)) {
        db.createObjectStore(OBJECT_STORE_NAME, {
          keyPath: "id",
          autoIncrement: true,
        });
      }
    };
  });
};
const addObjectToStore = (data) => {
  return new Promise((resolve, reject) => {
    const transaction = db.transaction(OBJECT_STORE_NAME, "readwrite");
    const objectStore = transaction.objectStore(OBJECT_STORE_NAME);
    const request = objectStore.add(data);

    request.onerror = (event) => {
      reject(`Error adding object: ${event.target.error}`);
    };
    request.onsuccess = (event) => {
      resolve(event.target.result);
    };
  });
};
const getAllObjectsFromStore = () => {
  return new Promise((resolve, reject) => {
    const transaction = db.transaction(OBJECT_STORE_NAME, "readonly");
    const objectStore = transaction.objectStore(OBJECT_STORE_NAME);
    const request = objectStore.getAll();

    request.onerror = (event) => {
      reject(`Error getting objects: ${event.target.error}`);
    };

    request.onsuccess = (event) => {
      resolve(event.target.result);
    };
  });
};
const GetAllObjectsFromStoreFilter = (fieldName, searchValue) => {
  return new Promise((resolve, reject) => {
    const transaction = db.transaction([OBJECT_STORE_NAME], "readonly");
    const objectStore = transaction.objectStore(OBJECT_STORE_NAME);

    const matchingObjects = [];
    const cursorRequest = objectStore.openCursor();

    cursorRequest.onsuccess = function (event) {
      const cursor = event.target.result;

      if (cursor) {
        // Check if the current record has the desired value
        if (cursor.value[fieldName] === searchValue) {
          // Collect the matching object
          matchingObjects.push(cursor.value);
        }

        // Move to the next record
        cursor.continue();
      } else {
        // Cursor has iterated through all records
        console.log("Matching objects:", matchingObjects);
      }
    };

    cursorRequest.onerror = function (event) {
      console.error("Error opening cursor:", event.target.error);
    };

    // Complete the transaction
    transaction.oncomplete = function () {
      resolve(matchingObjects)
    };

    transaction.onerror = function (event) {
      reject("Transaction failed:", event.target.error);
    };
  });
};
const clearObjectStore= (objectStoreName = "attractions") =>{
    const transaction = db.transaction([objectStoreName], "readwrite");
    var objectStore = transaction.objectStore(objectStoreName);
    var clearRequest = objectStore.clear();
    clearRequest.onsuccess = function() {
      console.log("Object store cleared successfully");
    };
    clearRequest.onerror = function(event) {
        console.error("Error clearing object store:", event.target.error);
    };
  }
export { openDB, addObjectToStore, getAllObjectsFromStore, GetAllObjectsFromStoreFilter, clearObjectStore };
